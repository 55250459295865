<template>
  <v-dialog 
    v-model="isModalShow" 
    max-width="400" 
    persistent
    >
    <!-- 警告 -->
    <modal-item-buy-alert
      :isModalShow="isModalAlertShow"
      @continue="isModalAlertShow = false; sendMail();"
      @cancel="isModalAlertShow = false; $emit('cancel')"
    />
    <!-- 利用規約 -->
    <modal-footer-terms
      :isShow="isTermsShow"
      @cancel="isTermsShow = false"
    />
    <v-card 
      max-width="600" 
      class="mx-auto"
      >
        <v-card-text class="pa-2">
          <v-card
            outlined
            class="mx-3 mt-3 mb-12"
          >
            <v-list-item three-line class="ticket">
              <v-list-item-content>
                <v-list-item-title class="headline">
                  <font size="2">{{ item.name }} </font>
                </v-list-item-title>
                <v-divider />
                <v-list-item-subtitle>
                  <font size="1"> {{ item.summary }} </font>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-text align="right">
              <font size="8">
              ¥{{ item.price | local }}
              </font>
            </v-card-text>
          </v-card>
          <p class="ma-3 text-caption">
            下記を入力のうえ「メールを送信」していただくと、購入用リンクが記載されたメールをお送りします。
          </p>
<!--
        <v-text-field
          v-model="password"
          label="パスワード"
          :append-icon="isPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
          autocomplete
          :type="isPasswordShow ? 'text' : 'password'"
          @click:append="isPasswordShow = !isPasswordShow"
        />
-->
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="mailAddress"
          label="メールアドレス" 
          prepend-icon="mdi-email-outline"
          max-width="300"
          hide-details="auto"
          class="pb-5"
          :rules="config.validation.mailRules"
        />
        <v-row justify="center">
          <v-checkbox v-model="isChecked">
            <template v-slot:label>
		<div>
			<a @click.stop="isTermsShow = true">
				利用規約
			</a>
			に同意する
		</div>
            </template>
          </v-checkbox>
        </v-row>
        <p 
           v-if="event.event_status_property_name == 'onLive'"
           class="error--text my-6"
        >
          ※このイベントには既に開始しているコンテンツがあります。確認のうえご購入ください。
        </p>
        <v-row justify="center">
          <v-card-actions>
            <v-btn 
              color="error" 
              @click="$emit('cancel')">
              キャンセル 
            </v-btn>
            <v-btn 
              v-bind:disabled="!isChecked" 
              color="success" 
              @click="buyCheck">
              メールを送信 
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import mixinPurchase from '@/mixins/purchase'
import ModalItemBuyAlert from '@/components/molecules/ModalItemBuyAlert.vue'
import ModalFooterTerms from '@/components/molecules/ModalFooterTerms'

export default {
 
  components:{
    ModalItemBuyAlert,
    ModalFooterTerms
  },
  mixins: [mixinPurchase],
 
  props: {
    event: Object,
    item : Object,
    isModalShow : Boolean,
  },
  computed: {
    ...mapGetters(['getHash'])
  },
  data(){
    return {
      isChecked : false,
      mailAddress : '',
//      password: '',
//      isPasswordShow: false,
      isModalAlertShow : false,
      isTermsShow: false
    }
  },
  inject:['isEmbedded', 'connectWebSocket'], // EventMain, EventEmbeddedでprovide
  methods: {
    // vuexのactionsを呼び出す
    ...mapActions("snackbar", ["openSnackbar"]),

    async buyCheck(){
//      const resPurchase = await this.$_getPurchase(this.eventId, this.mailAddress, this.password)
      const resPurchase = await this.$_getPurchase(this.event.id, this.mailAddress)
      const isPurchased = resPurchase.filter((v) => v.is_purchased).length > 0

      // 購入済みの場合
      if(isPurchased){
        this.$_setPurchase(this.event.id, this.mailAddress) // mixin
        await this.wc.post('checkDuplicate', [{'event_id':this.$route.params.id, 'hash':this.getHash}]).catch((err) => {
          console.log(err)
        })
	this.openSnackbar('既に購入済みです。ライブをお楽しみください。')
        this.connectWebSocket()
        this.$emit('purchased', this.mailAddress)
        return
      }
      // 既にライブが開始していたら購入の警告メッセージ
      const [eventData] = await this.wc.get('v_event',`w/id,${this.event.id}`).catch((err) => {
        console.log(err)
      })
      if(eventData.event_status_property_name == 'onLive'){
        this.isModalAlertShow = true
      } else {
        this.sendMail()
      }
    },
    // メール送信
    async sendMail(){
      const buydata = {
        event_id    : this.event.id,
        item        : JSON.stringify(this.item),
        order_mail  : this.mailAddress,
        //paid_url    : this.config.other.elepay_paid, 
        paid_url    : this.isEmbedded ? document.referrer : location.href,
        order_url   : this.config.other.elepay_order,
      }

      // elepayで購入URL発行、メール送信
      const res = await this.wc.post('order', buydata).catch((e) => { 
        console.log(e)
      })
      if(!res) return

      this.$_setOrder(this.event.id, this.mailAddress) // mixin
      this.openSnackbar('購入手続きのメールを送信しました')
      this.$emit('sentPurchaseMail')
    },
  },
}
</script>
<style>
.ticket .v-list-item__subtitle{
  -webkit-line-clamp: 100 !important;
  white-space: normal !important;
}
</style>
